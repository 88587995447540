<template>
  <div id="bsModalAddFile" aria-labelledby="bsModalLabel" class="modal fade"
       data-backdrop="static" data-keyboard="false"
       data-toggle="modal" role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="file">File</label>
            <input id="file" type="file" @change="uploadFile()" ref="file"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { saveNotification } from "@/lib/utils";
import { mapGetters } from "vuex";

export default {
  name: "AddFileModal",
  data() {
    return {
      title: "",
      confirmHook: () => {},
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  methods: {
    uploadFile() {
      const files = this.$refs.file.files;
      if (files.length > 0) {
        const file = files[0];
        if (file.type.startsWith("image/")) {
          const fd = new FormData();
          fd.append("file123", file);
          fd.append("MerchantID", this.currentUser.ID);
          // Upload it to amazon S3
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.post(`${apiBaseUrl}/MarketAPI?POSBLOB`, fd, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(response => {
            if (response.status === 200) {
              this.$refs.file.value = "";
              const fileURL = response.data;
              this.confirmHook(fileURL);
              this.close();
              this.$toast.fire("", "File added successfully", "success");
              saveNotification("File added successfully");
            }
          }).catch(error => {
            console.log(error);
          });
        } else {
          // Clear file
          this.$refs.avatar.value = "";
          this.$toast.fire("", "Please provide a valid image file", "error");
        }
      }
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalAddFile").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("add-file-modal", (args) => {
      this.title = args.title;
      this.confirmHook = args.confirm;
      $("#bsModalAddFile").modal("show");
    });
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex flex-col-sm gap-8 align-items-center justify-between">
          <h3 class="box-title">Market POS Files</h3>
          <div class="flex gap-8 align-items-center">
            <span class="text-bold text-danger">{{ searching }}</span>
            <input ref="searchInput" v-model="keyword" aria-label="search" class="form-control" placeholder="Search..."
              type="text" @keyup="search($event)" />
            <button class="btn btn-primary" @click="launchAddFileModal()">Add File</button>
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th>ID</th>
                <th>Merchant</th>
                <th>Name</th>
                <th>File</th>
                <th>Size</th>
                <th>MimeType</th>
                <th>Secure</th>
                <th>Created On</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="files.length > 0">
                <tr v-for="(file, index) in files" :key="index">
                  <td>{{ file.ID }}</td>
                  <td class="no-wrap">
                    <router-link v-if="file.MerchantID !== 0"
                      :to="{ name: 'user-detail', params: { id: file.MerchantID } }" tag="a">
                      {{ file.ShopTitle }}
                    </router-link>
                  </td>
                  <td>{{ file.Name }}</td>
                  <td>
                    <div class="flex align-items-center">
                      <silent-box :image="generateImageUrl(file)"></silent-box>
                      <span class="ml-8" v-if="!file.Secure">
                        <i class="fa fa-copy pointer" @click="copyToClipboard(file)"></i>
                      </span>
                    </div>
                  </td>
                  <td>{{ file.Size }}</td>
                  <td>{{ file.MimeType }}</td>
                  <td>
                    <span v-html="formatSecureStatus(file)"></span>
                  </td>
                  <td class="no-wrap">{{ formatDate(file.CreatedOn) }}</td>
                  <td class="action-sm pr-16">
                    <div>
                      <a v-if="!file.FinalVerification"
                        v-tooltip="{ content: 'Edit File', trigger: 'click focus hover' }" class="nav-action"
                        @click="launchUpdateFileModal(file)">
                        <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                          <path
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z">
                          </path>
                        </svg>
                      </a>
                      <a v-tooltip="{ content: 'Delete File', trigger: 'click focus hover' }" class="nav-action danger"
                        @click="deleteFile(file, index)">
                        <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                          <path
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16">
                          </path>
                        </svg>
                      </a>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="9">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
    <!-- Add File Modal -->
    <add-file-modal></add-file-modal>
    <!-- Update File Modal -->
    <update-file-modal></update-file-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pagination from "@/components/Pagination";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import { formatDate, saveNotification } from "@/lib/utils";
import UpdateFileModal from "@/components/modals/files/UpdateFileModal";
import AddFileModal from "../components/modals/files/AddFileModal";

export default {
  name: "MarketPosFiles",
  components: {
    AddFileModal,
    UpdateFileModal,
    Pagination,
  },
  data() {
    return {
      files: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      keyword: "",
      searching: "",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
    ]),
  },
  methods: {
    updatePage(page) {
      this.getPaginatedMarketPOSFiles(page);
    },
    search(e) {
      if (e.key !== "/" && e.key !== "Control") {
        this.searching = "Searching...";
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.expandedRowIndex = 0;
          this.getPaginatedMarketPOSFiles(1);
        }, 500);
      }
    },
    getPaginatedMarketPOSFiles(page = 1) {
      this.$store.commit(START_LOADING);
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMarketPOSFiles`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword ? this.keyword.trim() : "",
          limit: Number.parseInt(this.meta.limit),
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.files = response.data.data.files;
          this.meta = response.data.data.meta;
          if (this.keyword) {
            this.$nextTick(() => this.$refs.searchInput.focus());
          }
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    formatDate(val) {
      return formatDate(val);
    },
    generateImageUrl(record) {
      const url = record.Secure ? `https://app.cointopay.com/M_Images?secure=1&POS=1&ID=${record.Secure}`
        : `https://app.cointopay.com/M_Images?POS=1&ID=${record.ID}`;
      return {
        src: url,
        thumbnailHeight: "60px",
      };
    },
    copyToClipboard(record) {
      const url = `https://app.cointopay.com/M_Images?POS=1&ID=${record.ID}`;
      const self = this;
      navigator.clipboard.writeText(url).then(function () {
        self.$toast.fire("", "Copied to clipboard: " + url, "success");
      }, function (err) {
        console.error("Async: Could not copy text: ", err);
      });
    },
    formatSecureStatus(file) {
      const label = file.Secure ? "Yes" : "No";
      const lblClass = file.Secure ? "label-success" : "label-danger";
      return `<label class="label ${lblClass}">${label}</label>`;
    },
    launchUpdateFileModal(file, index) {
      this.$bus.$emit("update-file-modal", {
        title: "Update File",
        data: file,
        confirm: (url) => {
          console.log(url);
        },
      });
    },
    launchAddFileModal() {
      this.$bus.$emit("add-file-modal", {
        title: "Add File",
        data: null,
        confirm: (url) => {
          if (url) {
            this.getPaginatedMarketPOSFiles(1);
          }
        },
      });
    },
    deleteFile(file, index) {
      this.$swal.fire({
        title: "Market POS Files",
        text: "Are you sure, want to delete this file",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.get(`${apiBaseUrl}/MarketAPI`, {
            params: {
              del: file.ID,
              POS: 1,
            },
          }).then(response => {
            if (response.status === 200) {
              this.files.splice(index, 1);
              this.meta = Object.assign({}, this.meta, {
                total: this.meta.total - 1,
              });
              this.$toast.fire("", "File deleted successfully", "success");
              saveNotification("File deleted successfully");
            }
          }).catch(error => {
            console.log(error);
          });
        }
      });
    },
    handleSlashKey(e) {
      if (e.key === "/") {
        e.preventDefault();
        this.$refs.searchInput.focus();
      }
    },
  },
  mounted() {
    this.getPaginatedMarketPOSFiles();
    window.addEventListener("keydown", this.handleSlashKey);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleSlashKey);
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
